// 初始化cesium
export function MapInit(cesiumContainer) {
   
    Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MTQzMWRhZS04ZDkyLTRkMmUtYmYyZi1mOGY2ZmRkNDgyZjAiLCJpZCI6MzkwNDIsImlhdCI6MTYwNzA3MjQ1Nn0.s8_tJUR4Y8Rd8spunTJxrh0GrzNbVGYRyXyh4f9eWDs'

    // 创建viewer实例
    let viewer = new Cesium.Viewer(cesiumContainer, {
        animation: false, // 是否显示动画控件
        shouldAnimate: true,  // 当动画控件出现，用来控制是否通过旋转控件，旋转场景
        homeButton: false, // 是否显示Home按钮
        fullscreenButton: false, // 是否显示全屏按钮
        baseLayerPicker: false, // 是否显示图层选择控件
        navigationInstructionsInitiallyVisible: false,  // 帮助按钮，初始化的时候是否展开
        geocoder: false, // 是否显示地名查找控件
        timeline: false, // 是否显示时间线控件
        sceneModePicker: false, // 是否显示投影方式控件
        navigationHelpButton: false, // 是否显示帮助信息控件
        infoBox: false, // 是否显示点击要素之后显示的信息
        requestRenderMode: true, // 启用请求渲染模式
        scene3DOnly: false, // 每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, // 初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, // 全屏时渲染的HTML元素 暂时没发现用处
        selectionIndicator: false,  // 关闭点击效果
        
        // terrainProvider: Cesium.createWorldTerrain(),
    })


    MapViewInit(viewer);

    return viewer;
}


export function MapViewInit(viewer) {
   
    // 去除版权信息、大气层、天空盒、背景色
    viewer._cesiumWidget._creditContainer.style.display = 'none'              
    // viewer.scene.skyAtmosphere.show=false; // 关闭大气层     
    // viewer.scene.skyBox.show = false;      // 天空盒               
    // viewer.scene.backgroundColor = new Cesium.Color(0.0, 0.0, 0.0, 0.0);    // 背景色


    // 取消左键双击事件
    viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);      

    // 相机离地表的最低高度(避免转到地底)
    viewer.scene.screenSpaceCameraController.minimumZoomDistance = 10;

    // 开启场景光照
    viewer.scene.globe.enableLighting = true;

    // // 深度显示（用于湖泊河流 水深对周围景观的影响）
    // viewer.scene.globe.depthTestAgainstTerrain = true;


    // // DirectionalLight 表示 从无限远的地方向单一方向发射的光。(关闭时间对于模型亮度的影响)
    // viewer.scene.light = new Cesium.DirectionalLight({ 
    //     direction: new Cesium.Cartesian3(0.354925, -0.890918, -0.283358)
    // })

    return viewer;
}


// 清空viewer所有内容
export function MapViewerRemoveAll(viewer) {
    viewer.dataSources.removeAll();
    viewer.entities.removeAll();
    viewer.scene.postProcessStages.removeAll();
}


// 显示摄像机和点击点位置的调试信息
export function MapDebugCamera(viewer, movement) {
    // 摄像机位置
    let position = viewer.scene.camera.position            
    let cartographic = Cesium.Cartographic.fromCartesian(position);
    let x = Cesium.Math.toDegrees(cartographic.longitude);
    let y = Cesium.Math.toDegrees(cartographic.latitude);
    let z = cartographic.height;
    
    let heading = Cesium.Math.toDegrees(viewer.scene.camera.heading);   // 偏航角
    let pitch = Cesium.Math.toDegrees(viewer.scene.camera.pitch);       // 俯仰角
    let roll = Cesium.Math.toDegrees(viewer.scene.camera.roll);         // 滚转角

    console.log("camera: %f, %f, %f, %f, %f, %f", x, y, z, heading, pitch, roll);

    // 点击点位置
    var clickearthPosition = viewer.camera.pickEllipsoid(movement.position, viewer.scene.globe.ellipsoid);
    var clickcartographic = Cesium.Cartographic.fromCartesian(clickearthPosition, viewer.scene.globe.ellipsoid, new Cesium.Cartographic());
    var clicklng = Cesium.Math.toDegrees(clickcartographic.longitude);
    var clicklat = Cesium.Math.toDegrees(clickcartographic.latitude);
    var clickheight = clickcartographic.height;
    console.log("click: %f, %f, %f", clicklng, clicklat, clickheight)
}


// 加载天地图影像数据
export function MapAddDom_Tianditu(viewer) {

    let token = "6e9cebf98fdd9477585ef2a0d3857163";
    // 服务域名
    let tdtUrl = 'https://t{s}.tianditu.gov.cn/';
    // 服务负载子域
    let subdomains=['0','1','2','3','4','5','6','7'];

    // 天地图叠加影像服务
    let imgMap = new Cesium.UrlTemplateImageryProvider({
        url: tdtUrl + 'DataServer?T=img_w&x={x}&y={y}&l={z}&tk=' + token,
        subdomains: subdomains,
        tilingScheme : new Cesium.WebMercatorTilingScheme(),
        maximumLevel : 18
    });
    return viewer.imageryLayers.addImageryProvider(imgMap);   


    // // geoserver测试1
    // var provider = new Cesium.WebMapServiceImageryProvider({
    //     url: 'http://47.117.2.79:6119/geoserver/smartpt/wms',
    //     layers: 'smartpt:tile_0',
    //     parameters: {
    //         service: 'WMS',
    //         format: 'image/png',
    //         transparent: true,
    //     }
    // });
    // viewer.imageryLayers.addImageryProvider(provider);

    // // geoserver测试2
    // var provider = new Cesium.WebMapTileServiceImageryProvider({
    //     url: 'http://127.0.0.1:8080/geoserver/gwc/service/wmts/rest/smartpt:tile_0/{style}/{TileMatrixSet}/{TileMatrixSet}:{TileMatrix}/{TileRow}/{TileCol}?format=image/png',
    //     layer: 'smartpt:tile_0',
    //     style: '',
    //     format: 'image/png',
    //     tileMatrixSetID: 'EPSG:4326'      //一般使用EPSG:3857坐标系        
    // });
    // viewer.imageryLayers.addImageryProvider(provider);
                         
}


// 加载高德影像数据
export function MapAddDom_Amap(viewer) {

    // 高德影像服务
    let imgMap = new Cesium.UrlTemplateImageryProvider({
        url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=6"
        // url: "//webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
        // srcCoordType: "GCJ02",
        // dstCoordType: "WGS84",
        // maximumLevel: 18,     
    });
    return viewer.imageryLayers.addImageryProvider(imgMap);       
}



// 加载高程数据
export function MapAddDem(viewer, url) {
    var terrainProvider = new Cesium.CesiumTerrainProvider({
        url: url,
        
        // requestWaterMask: true,     //开启法向量
        // requestVertexNormals: true, //开启水面特效
    })

    viewer.terrainProvider = terrainProvider;  // 加载高程
}


// 飞入
export function MapFlyToDuration(viewer, duration, longitude, latitude, height, heading, pitch, roll){
    
    // // 将经纬度转换为世界坐标           
    // var target = Cesium.Cartesian3.fromDegrees(longitude, latitude, height);
    // var offset = new Cesium.Cartesian3(-70.06, -68.64, 6.0908)
    // viewer.scene.camera.lookAt(target, offset);

    // 飞入
    viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),   
        orientation: {
            heading: Cesium.Math.toRadians(heading),
            pitch: Cesium.Math.toRadians(pitch),
            roll: Cesium.Math.toRadians(roll)
        },
        complete: function callback() {
            // 定位完成之后的回调函数
        },
        duration: duration // 设置飞行持续时间，默认会根据距离来计算
    });
}
  

// 添加三维模型
export function MapAdd3DModel(viewer, modelurl, longitude, latitude, height) {
    
    var modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(
        Cesium.Cartesian3.fromDegrees(longitude, latitude, height)
    );
    
    viewer.scene.primitives.add(Cesium.Model.fromGltf({
        url: modelurl,
        modelMatrix: modelMatrix,
        scale: 500.0
    }));
    
}


// 添加三维建筑
export function MapAdd3DTiles(viewer, url, modelMatrix, imageBasedLightingFactor) {

    let tileset = new Cesium.Cesium3DTileset({
        url: url,
        maximumScreenSpaceError: 2,             //最大屏幕空间误差
        maximumNumberOfLoadedTiles: 1000,       //最大加载瓦片个数
        modelMatrix: Cesium.Matrix4.fromArray(modelMatrix),        
        imageBasedLightingFactor: new Cesium.Cartesian2(imageBasedLightingFactor[0], imageBasedLightingFactor[1]),
    });
    
    // viewer.scene.primitives.add(tileset);
    tileset.readyPromise.then(function (tileset) {
        viewer.scene.primitives.add(tileset);     
    }).otherwise(function (error) {
        console.log(error);
    });

    viewer.zoomTo(tileset);
}


// 添加倾斜摄影
export function MapAddTiltPhoto(viewer, url, heightOffset) {

    var tileset = new Cesium.Cesium3DTileset({
        url: url,
    });
    tileset.readyPromise.then(function (tileset) {
        var cartographic = Cesium.Cartographic.fromCartesian(
            tileset.boundingSphere.center
        );
        var surface = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            0.0
        );
        var offset = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            heightOffset    //填高度差值
        );
        var translation = Cesium.Cartesian3.subtract(
            offset,
            surface,
            new Cesium.Cartesian3()
        );
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
        viewer.scene.primitives.add(tileset);
        // viewer.flyTo(tileset)
     
    }).otherwise(function (error) {
        console.log(error);
    });
}


// 抛物线1：两点之间抛物线绘制函数，twoPoints是一个数组：[lon1,lat1,lon2,lat2]
export function MapAnimatedParabola(viewer, twoPoints) {  //动态抛物线绘制
    let startPoint = [twoPoints[0],twoPoints[1],0]; //起点的经度、纬度
    let step = 80;  //线的数量，越多则越平滑
    let heightProportion = 0.125; //最高点和总距离的比值(即图中H比上AB的值)
    let dLon = (twoPoints[2] - startPoint[0])/step;  //经度差值
    let dLat = (twoPoints[3] - startPoint[1])/step;  //纬度差值
    let deltaLon = dLon * Math.abs(111000*Math.cos(twoPoints[1]));  //经度差(米级)
    let deltaLat = dLat * 111000;  //纬度差(米),1纬度相差约111000米
    let endPoint = [0,0,0];  //定义一个端点（后面将进行startPoint和endPoint两点画线）
    let heigh = (step * Math.sqrt(deltaLon*deltaLon+deltaLat*deltaLat) * heightProportion).toFixed(0);
    let x2 = (10000*Math.sqrt(dLon*dLon+dLat*dLat)); //小数点扩大10000倍，提高精确度
    let a = (heigh/(x2*x2));  //抛物线函数中的a
    function y(x,height) {  //模拟抛物线函数求高度
        //此处模拟的函数为y = H - a*x^2  (H为高度常数)
        return height - a*x*x;
    }
    for(let i = 1;i <= step; i++){  //逐“帧”画线
        endPoint[0] = startPoint[0] + dLon; //更新end点经度
        endPoint[1] = startPoint[1] + dLat; //更新end点纬度
        let x = x2*(2*i/step-1);  //求抛物线函数x
        endPoint[2] = (y(x,heigh)).toFixed(0);  //求end点高度
        viewer.clock.currentTime = Cesium.JulianDate.now(); //将时钟指针移到当前时间
        //这里viewer是容器初始化时new Cesium.Viewer构造的: var viewer = new Cesium.Viewer('mapContainer', {...});
        let IsoTime = Cesium.JulianDate.now(); //获取当前时间
        viewer.entities.add({  //添加动态线
            polyline: {
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(startPoint.concat(endPoint)),
                width: 4,
                material: new Cesium.PolylineOutlineMaterialProperty({
                    color: Cesium.Color.GOLD,
                    outlineWidth: 0.3,
                })
            },
            availability: new Cesium.TimeIntervalCollection([new Cesium.TimeInterval({ //设置显示的时间区间
                start: {
                    dayNumber: IsoTime.dayNumber,
                    secondsOfDay: IsoTime.secondsOfDay+((i-1)*300),
                },
                stop: {
                    dayNumber: IsoTime.dayNumber,
                    secondsOfDay: IsoTime.secondsOfDay+(i*300),
                },
            })]),
        });
        viewer.entities.add({  //添加静态线
            polyline: {
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(startPoint.concat(endPoint)),
                width: 4,
                material: new Cesium.PolylineGlowMaterialProperty({
                    color: Cesium.Color.AQUA.withAlpha(0.9),
                    outlineWidth: 0.3,
                    glowPower : 0.3,
                })
            },
        });
        // end点变为start点
        startPoint[0] = endPoint[0];
        startPoint[1] = endPoint[1];
        startPoint[2] = endPoint[2];
    }
    viewer.clock.shouldAnimate = true;  //启动时钟开始转动
    viewer.clock.multiplier = 1600;  //时钟转动速度
}

// 抛物线2
export function MapParabola(viewer, twoPoints) {  //抛物线绘制
    let startPoint = [twoPoints[0],twoPoints[1],0]; //起点的经度、纬度
    let step = 80;  //线的多少，越多则越平滑(但过多浏览器缓存也会占用越多)
    let heightProportion = 0.5; //最高点和总距离的比值
    let dLon = (twoPoints[2] - startPoint[0])/step;  //经度差值
    let dLat = (twoPoints[3] - startPoint[1])/step;  //纬度差值
    let deltaLon = dLon * Math.abs(111000*Math.cos(twoPoints[1]));  //经度差(米级)
    let deltaLat = dLat * 111000;  //纬度差(米),1纬度相差约111000米
    let endPoint = [0,0,0];  //定义一个端点（后面将进行startPoint和endPoint两点画线）
    let heigh = (step * Math.sqrt(deltaLon*deltaLon+deltaLat*deltaLat) * heightProportion).toFixed(0);
    let x2 = (10000*Math.sqrt(dLon*dLon+dLat*dLat)); //小数点扩大10000倍，提高精确度
    let a = (heigh/(x2*x2));
    function y(x,height) { return height - a*x*x; }
    for(var i = 1;i <= step; i++){  //逐“帧”画线
        endPoint[0] = startPoint[0] + dLon; //更新end点经度
        endPoint[1] = startPoint[1] + dLat; //更新end点纬度
        let x = x2*(2*i/step-1);  //求抛物线函数x
        endPoint[2] = (y(x,heigh)).toFixed(0);  //求end点高度
        viewer.entities.add({  //添加静态线
            polyline: {
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(startPoint.concat(endPoint)),
                width: 4,
                material: new Cesium.PolylineGlowMaterialProperty({
                    color: Cesium.Color.AQUA.withAlpha(0.9),
                    outlineWidth: 0.3,
                    glowPower : 0.3,
                })
            },
        });
        // end点变为start点
        startPoint[0] = endPoint[0];
        startPoint[1] = endPoint[1];
        startPoint[2] = endPoint[2];
    }
}


// 画线
export function MapPolyline(viewer, positions) {  

    viewer.entities.add({
        polyline: {
            positions: positions,
            width: 3.0,
            // material: new Cesium.PolylineGlowMaterialProperty({
            //     color: Cesium.Color.DEEPSKYBLUE,
            //     glowPower: 0.25,
            // }),            
            material: new Cesium.PolylineDashMaterialProperty({     // 虚线
                color: Cesium.Color.DEEPSKYBLUE,
                dashLength: 30 //短划线长度
            })
        },
    });

}
