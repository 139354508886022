import {request} from './request';


// 多媒体数据(查询单个)
export function getMedia(id){
  return request({
    url:'/api/v1/media/getmedia?id='+id,
  })
}


// code数据(查询单个)
export function getCode(codetype, id){
  return request({
    url:'/api/v1/code/getcode?codetype='+ codetype +'&id='+id,
  })
}

